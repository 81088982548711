/**
 * This module contains centralized definitions for making API requests from the
 * frontend.
 *
 */

import axios, { Method } from "axios";
import { toastErrorHandler } from "components/ToastErrorHandler";

export type ErrorHandler = (
  error: any,
  method: Method,
  url: string,
  data: any,
  config: any,
) => void;

const request = async (
  method: Method,
  url: string,
  data = {},
  config = {},
  onError: ErrorHandler = toastErrorHandler,
) => {
  const instance = axios.create({});
  try {
    return await instance({
      method,
      url,
      data,
      ...config,
    });
  } catch (e) {
    onError(e, method, url, data, config);
    throw e;
  }
};

const get = (url: string, config = {}, onError?: ErrorHandler) => {
  return request("GET", url, {}, config, onError);
};

const post = (
  url: string,
  data: object,
  config = {},
  onError?: ErrorHandler,
) => {
  return request("POST", url, data, config, onError);
};

const put = (
  url: string,
  data: object,
  config = {},
  onError?: ErrorHandler,
) => {
  return request("PUT", url, data, config, onError);
};

const patch = (
  url: string,
  data: object,
  config = {},
  onError?: ErrorHandler,
) => {
  return request("PATCH", url, data, config, onError);
};

const remove = (url: string, config = {}, onError?: ErrorHandler) => {
  return request("DELETE", url, {}, config, onError);
};

// SWR shim which resolves the response data
// for the caller
const getJSON = async (url: string) => {
  const response = await get(url);
  return response.data;
};

export const ApiService = {
  get,
  post,
  put,
  patch,
  remove,
  getJSON,
};
