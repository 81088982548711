// TODO: Remove implicit assumption that "text" column is primary text column.
export const TEXT_IS_PRIMARY_COLUMN = "text";

export const PLACEHOLDER_DASHES = "– –";

// Internal links
export const HOMEPAGE_LINK = "/";
export const PRICING_PAGE_LINK = "/pricing";
export const PLATFORM_PAGE_LINK = "/";
export const PROGRAMMATIC_PAGE_LINK = "/programmatic";
export const ABOUT_PAGE_LINK = "/about";

// Subdomains
export const CAREERS_LINK =
  "https://www.ycombinator.com/companies/humanloop/jobs";
export const HUMANLOOP_APP_LINK = "https://app.humanloop.com";
export const HUMANLOOP_APP_SIGNUP = "https://app.humanloop.com/signup";
export const HUMANLOOP_APP_LOGIN = "https://app.humanloop.com/login";
export const HUMANLOOP_CAREERS_LINK = "https://careers.humanloop.com";

// Docs
export const DOCS_LINK = "https://humanloop.com/docs/";
// Docs - concepts / topics
export const DOCS_DATASETS = "https://humanloop.com/docs/datasets";
export const DOCS_ACCESS_ROLES = "https://humanloop.com/docs/access-roles";
export const DOCS_TOOLS = "https://humanloop.com/docs/tools";
// Docs - guides
export const DOCS_GENERATE_AND_LOGGING =
  "https://humanloop.com/docs/guides/generate-and-log-with-the-sdk";
export const DOCS_UPLOAD_DATA =
  "https://humanloop.com/docs/guides/upload-historic-data";
export const DOCS_SESSIONS =
  "https://humanloop.com/docs/guides/logging-session-traces";
export const DOCS_EVALUATIONS = "https://humanloop.com/docs/guides/evaluation";
export const DOCS_EVALUATORS_SELF_HOSTED =
  "https://humanloop.com/docs/guides/self-hosted-evaluations";
export const DOCS_EVALUATORS_HUMAN =
  "https://humanloop.com/docs/guides/evaluating-with-human-feedback";
export const DOCS_EVALUATORS_LLM =
  "https://humanloop.com/docs/guides/use-llms-to-evaluate-logs";
export const DOCS_FINETUNING =
  "https://humanloop.com/docs/guides/finetune-a-model";
export const DOCS_EXPERIMENTS =
  "https://humanloop.com/docs/guides/run-an-experiment";

// Socials and marketing
export const GITHUB_LINK = "https://github.com/humanloop";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/humanloop";
export const TWITTER_LINK = "https://x.com/humanloop";
export const SLACK_LINK =
  "https://join.slack.com/t/humanloop-community/shared_invite/zt-15bpaukat-w99Ns5wyQiuKVPSxyLqHlQ";
export const PROGRAMMATIC_TYPEFORM =
  "https://qnar0f4q62y.typeform.com/to/LlpY8vSt";
export const HUMANLOOP_TYPEFORM__INVALID =
  "https://qnar0f4q62y.typeform.com/humanloop"; // INVALID, DO NOT USE
export const LLM_TYPEFORM = "https://qnar0f4q62y.typeform.com/humanloop-llm";

export const VANTA_TRUST_REPORT_LINK = "https://trust.humanloop.com";
export const DEMO_FORM_LINK = "/demo";
export const YC_INTERVIEW_LINK = "https://www.youtube.com/watch?v=hQC5O3WTmuo";

export const APPLE_PODCASTS_LINK =
  "https://podcasts.apple.com/us/podcast/e20-the-great-implementation-with-raza-habib/id1669813431?i=1000610901030";

// These sizes are meant to extend the spacings set in tailwind.
export const PANE_BREAKPOINT_2XS = 350;
export const PANE_BREAKPOINT_XS = 475;

export const NUMBER_OF_JOB_OPENINGS = 3;

// Hubspot forms
export const HUBSPOT_DEMO_FORM_DEV = "c64b7e79-b4f2-41be-854b-b69cf2b2f245";
export const HUBSPOT_DEMO_FORM = "80932263-b169-4ae6-969c-1c3a108426f7";
export const HUBSPOT_GPT3_INTERESTED_FORM =
  "7ac5bc94-a40d-4dfb-aabb-1403a6fa6be1";
export const HUBSPOT_MICROSOFT_FOR_STARTUPS_FORM =
  "70d709e5-dddf-4d5d-9436-3670f1fe2a6f";
export const HUBSPOT_NEWSLETTER_FORM = "1b98fcef-d26c-4f50-88d8-05eb262750fb";
export const HUBSPOT_PODCAST_EMAIL_FORM =
  "53d6f7e1-5343-4f73-aca7-d0a70645b2c4";
