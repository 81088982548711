import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import siteMetadata from "@/data/siteMetadata";

export interface TitleAndMetaTagsProps {
  title?: string;
  description?: string;
  image?: string;
  pathname?: string;
}

export function TitleAndMetaTags(props: TitleAndMetaTagsProps) {
  const router = useRouter();
  const title = props.title || siteMetadata.title;
  const description = props.description || siteMetadata.description;
  const image = props.image || siteMetadata.socialBanner;
  const url =
    typeof window === "undefined"
      ? siteMetadata.siteUrl
      : window.location.origin;

  const imageUrl = `${url}${image}`;
  const path = props.pathname || router.pathname;
  const canonicalUrl = `https://humanloop.com${path}`;

  return (
    <Head>
      {/* TODO: merge this with CommonSEO */}
      <title>{title}</title>

      <meta name="description" content={description} />

      <link rel="canonical" href={canonicalUrl} />

      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      <meta name="twitter:site" content="@humanloop" />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
}
