import { Method } from "axios";
import toast from "react-hot-toast";
import { ErrorHandler } from "../services/api.service";

interface Props {
  message: string;
  method: Method;
  url: string;
}

const ErrorToast = ({ message, method, url }: Props) => {
  return (
    <div>
      {message}
      <br />
      <span className="text-11-14 text-gray-800">
        {method} on {url}
      </span>
    </div>
  );
};

export const toastErrorHandler: ErrorHandler = (
  e: any,
  method: Method,
  url: string,
  data: any,
  config: any,
) => {
  console.log("Failed to fetch:", e, method, url, data, config);
  if (e.message) {
    toast.error(<ErrorToast message={e.message} method={method} url={url} />, {
      duration: 8000,
    });
  }
};
