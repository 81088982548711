import Link from "next/link";
import React, { ForwardedRef, forwardRef } from "react";
import { classNames } from "./utils/classNames";
import { ConditionalWrapper } from "./utils/wrap";

export type IconButtonSize = 12 | 16 | 20 | 24 | 32 | 40 | 48;

interface IconButtonProps {
  // Regular button props https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
  active?: boolean;
  isWait?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  value?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  baseStyle?: boolean;

  // Our custom props
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  size?: IconButtonSize;
  className?: string;
  style?: React.CSSProperties;
  shade?: "black" | "gray" | "blue";
  square?: boolean; // No corner radius if true.
  // When used as a link
  href?: string;
  target?: string;
  rel?: string;
}

const IconButton = (
  props: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element => {
  const type = props.type || "button";
  const size = props.size || "24"; // Refers to the outer button size
  const shade = props.shade || "black";
  const square = props.square || false;

  const classes = {
    base: classNames(
      "inline-flex justify-center items-center relative select-none",
      !square && "rounded",
    ),
    size: {
      12: "w-12 h-12",
      16: "w-16 h-16",
      20: "w-20 h-20",
      24: "w-24 h-24",
      32: "w-32 h-32",
      40: "w-40 h-40",
      48: "w-48 h-48",
    },
    icon: {
      12: "w-8 h-8",
      16: "w-8 h-8",
      20: "w-12 h-12",
      24: "w-16 h-16",
      32: "w-20 h-20",
      40: "w-20 h-20",
      48: "w-24 h-24",
    },
    styling: {
      ["black"]: {
        base: "focus:outline-none focus:ring focus:ring-gray-200",
        normal: "text-black hover:bg-gray-100 active:bg-gray-200",
        disabled: "text-gray-400 hover:bg-gray-100",
        active: "text-black bg-gray-200 hover:bg-gray-200 active:bg-gray-200",
      },
      ["gray"]: {
        base: "focus:outline-none focus:ring focus:ring-gray-300",
        normal: "text-gray-700 hover:bg-gray-200 active:bg-gray-300",
        disabled: "text-gray-400 hover:bg-gray-100",
        active:
          "text-gray-900 bg-gray-200 hover:bg-gray-200 active:bg-gray-200",
      },
      ["blue"]: {
        base: "focus:outline-none focus:ring focus:ring-gray-300",
        normal: "text-blue-700 hover:bg-gray-200 active:bg-gray-300",
        disabled: "text-blue-400 hover:bg-gray-100",
        active:
          "text-blue-900 bg-gray-200 hover:bg-gray-200 active:bg-gray-200",
      },
    },
    disabled: props.isWait ? "cursor-wait" : "cursor-not-allowed",
  };

  const shadeStyling = classes.styling[shade];
  const Element = props.href ? "a" : "button";

  return (
    <ConditionalWrapper
      condition={!!props.href}
      wrapper={(children) => {
        return (
          <Link href={props.href as string} passHref>
            {children}
          </Link>
        );
      }}
    >
      <button
        ref={ref}
        type={type}
        onClick={props.onClick}
        className={classNames(
          props.className,
          classes.base,
          classes.size[size],
          shadeStyling.base,
          !props.baseStyle && props.disabled && classes.disabled,
          !props.baseStyle &&
            (props.disabled
              ? shadeStyling.disabled
              : props.active
                ? shadeStyling.active
                : shadeStyling.normal),
        )}
        disabled={props.disabled}
        style={props.style}
      >
        <props.Icon className={classes.icon[size]} />
      </button>
    </ConditionalWrapper>
  );
};
export default forwardRef<HTMLButtonElement, IconButtonProps>(IconButton);
